import { MagnifyingGlassIcon, ArrowTrendingUpIcon, ChatBubbleLeftIcon, FunnelIcon, FaceSmileIcon } from '@heroicons/react/24/outline'
import Lottie from 'lottie-react';
import webLottie from './assets/web-lottie.json';

const features = [
  {
    letter: 's',
    name: 'wie Shopify',
    description: 'Entwicklung und Optimierung von leistungsstarken Online-Shops.',
    icon: MagnifyingGlassIcon,
  },
  {
    letter: 'm',
    name: 'wie Merchandising & Migration',
    description: 'Effektive Produktpräsentation und nahtlose Migration Ihrer bestehenden Shops zu Shopify.',
    icon: ChatBubbleLeftIcon,
  },
  {
    letter: 'p',
    name: 'wie Performance',
    description: 'Optimierung für Geschwindigkeit, Benutzerfreundlichkeit und Conversion-Rate.',
    icon: ArrowTrendingUpIcon,
  },
  {
    letter: 'l',
    name: 'wie Liquid & Layout',
    description: 'Anpassung von Shopify-Themes mit Liquid und Gestaltung ansprechender Shop-Layouts.',
    icon: FunnelIcon,
  },
  {
    letter: 'x',
    name: 'wie Experience',
    description: 'Gestaltung eines herausragenden Einkaufserlebnisses, das Ihre Kunden begeistert und bindet.',
    icon: FaceSmileIcon,
  }
]


function Intro() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">simple x</p>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Wofür steht smplx?</h2>
              <p className="mt-6 text-base leading-7 text-gray-700">
              Als Experten für Shopify und Performance-orientierte Online-Shops bieten wir maßgeschneiderte Lösungen, die Ihre Marke stärken und Ihre Umsätze maximieren. Wir sorgen dafür, dass Ihr Shop gefunden wird und Besucher zu treuen Kunden werden.   
              </p>
            </div>
          </div>
        </div>
        <div className="-mt-12 -ml-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <Lottie className="sm:max-w-none md:-ml-4 lg:-ml-0" animationData={webLottie} loop={true} />  
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              
              <dl className="max-w-xl text-base space-y-8 leading-7 text-gray-700 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon className="absolute top-1 left-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      <span className='text-indigo-600 font-heading-font'>{feature.letter} {feature.name}</span>
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intro;