import { LinkIcon, SparklesIcon, LightBulbIcon, PuzzlePieceIcon, FingerPrintIcon } from '@heroicons/react/24/outline';
import Lottie from 'lottie-react';
import credoLottie from './assets/credo-lottie.json';

const features = [
  {
    description: 'Anstatt zu überzeugen, fördern wir. ',
    icon: LinkIcon,
  },
  {
    description: 'Anstatt zu verkaufen, leiten wir.',
    icon: SparklesIcon,
  },
  {
    description: 'Anstatt aufdringlich zu sein, schaffen wir Klarheit.',
    icon: LightBulbIcon,
  },
  {
    description: 'Anstatt zu pitchen, versuchen wir zu verstehen.',
    icon: PuzzlePieceIcon,
  },
  {
    description: 'Anstatt zu spammen, erzielen wir eine Wirkung.',
    icon: FingerPrintIcon,
  }
]

function Feature() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">Unser Credo</p>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Vertrauen entsteht, wenn Taten auf Worte folgen!</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Wir legen vollen Fokus auf Ihren Erfolg. Aus diesem Grund agieren wir anhand einiger Regeln:
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline text-gray-900">
                      <feature.icon className="absolute top-1 left-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                      {feature.description}
                    </dt>{' '}
                    
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <Lottie className="sm:max-w-none md:-ml-4 lg:-ml-0" animationData={credoLottie} loop={true} />   
          
          
        </div>
      </div>
    </div>
  )
}

export default Feature;