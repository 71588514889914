import { ChartBarSquareIcon, MagnifyingGlassIcon, BeakerIcon, CodeBracketSquareIcon } from '@heroicons/react/24/outline'


const services = [
    {
        name: 'SEA/PPC',
        icon: ChartBarSquareIcon,
        text: 'Basierend auf Daten und mit der Unterstützung KI-basierter Tools schalten wir für Ihre Zielgruppe optimierte Anzeigen, die Ihre potentiellen Kunden dort abholen wo sie sich bewegen.',
    },
    {
        name: 'SEO',
        icon: MagnifyingGlassIcon,
        text: 'Wir unterstützen Sie dabei Ihre Website dahingehend zu optimieren, dass Sie bei den für Ihr/en Service/Produkt relevanten Suchanfragen höhere Rankings erzielen.',
    },
    {
        name: 'CRO',
        icon: BeakerIcon,
        text: 'Wir schaffen ein positives Nutzererlebnisses auf Basis von datenbasierten Analsyen, um die Effektivität bei der Umwandlung von Besuchern in Kunden zu verbessern.',
    },
    {
        name: 'DEV',
        icon: CodeBracketSquareIcon,
        text: 'Die Entwicklung, die das Re-Design oder die Implementierung spezifischer UX-optimierter Add-Ons für Ihre Website umfasst.',
    }
]

function Services () {
    return (
    <section className="bg-primary-100 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="max-w-screen-md mb-8 lg:mb-16">
                <h2 className="mb-4 text-4xl tracking-tight font-bold text-gray-900">Dieser Service ist für Unternehmen wie Ihres gedacht</h2>
                <p className="text-gray-600 text-lg"></p>
            </div>
            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0">
                {services.map((service) => (
                <div key={service.name}>
                    <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-neutral-50 lg:h-12 lg:w-12">
                    <service.icon className="top-1 left-1 h-5 w-5 text-indigo-600" aria-hidden="true" />   
                    </div>
                    <h3 className="mb-2 text-xl font-bold">{service.name}</h3>
                    <p className="text-gray-600">{service.text}</p>
                </div>
                ))}
            </div>
        </div>
    </section>
    )
}

export default Services;


