import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import './output.css';
import './fonts.css';
import Header from './components/Header';
import Footer from './components/Footer2';
import Home from './pages/Home';
import Impressum from './pages/Impressum';
import Datenschutz from './pages/Datenschutz';
const domainGroupId = 'd28543a3-6138-436e-ae26-f6445d85b8bc';



function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/datenschutz" component={Datenschutz} />
        <Footer />
      </div>
    </Router>    
  );
}

export default App;
