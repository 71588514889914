import jclay from './assets/J-clay-logo.png';
import coenen from './assets/Coenen-logo.png';
import tolsen from './assets/Tolsen-logo.png';
import heydecke from './assets/Heydecke.png';
import amg from './assets/Amg-recruiting-logo.png';
import conmoto from './assets/Conmoto-logo.png';
import amd from './assets/Amd-netz-logo.png';
import lineup from './assets/Line-up-logo.png';
import cds from './assets/Cds-marketing-logo.png';
import zmyle from './assets/zmyle-logo.png';
import mwb from './assets/Malaga-workbay-logotipo.png';
import sur from './assets/Diario-sur-logo.png';
import sg06ah from './assets/Sg-coesfeld-06-logo.png';
import promalaga from './assets/Pro-malaga-logo.png';
import dmm from './assets/Digital-marketing-malaga.png';
import tramontina from './assets/Tramontina.png';
import vondiem from './assets/VONDIEM.png';
import actaco from './assets/ACTA&CO.png';
import concrete from './assets/Concrete.png';
import hy from './assets/HY.png';
import solit from './assets/solit.marketing.png'

const customers = [
  {
    name: 'J.Clay Socks',
    imageSrc: jclay,
    imageAlt: 'J.Clay Logo',
  },
  {
    name: 'solit.marketing',
    imageSrc: solit,
    imageAlt: 'solit Logo',
  },
  {
    name: 'HelpYourself',
    imageSrc: hy,
    imageAlt: 'HelpYourself Logo',
  },
  {
    name: 'acta&co',
    imageSrc: actaco,
    imageAlt: 'ACTA&CO Logo',
  },
  {
    name: 'concrete',
    imageSrc: concrete,
    imageAlt: 'concrete Logo',
  },
  {
    name: 'tramontina',
    imageSrc: tramontina,
    imageAlt: 'Tramontina Logo',
  },
  {
    name: 'VONDIEM',
    imageSrc: vondiem,
    imageAlt: 'VONDIEM Logo',
  },
  {
    name: 'Heyecke',
    imageSrc: heydecke,
    imageAlt: 'Heydecke Logo',
  },
  {
    name: 'Coenen Publishing',
    imageSrc: coenen,
    imageAlt: 'Coenen Publishing Logo',
  },
  {
    name: 'Tolsen Tools',
    imageSrc: tolsen,
    imageAlt: 'Tolsen Tools Logo',
  },
  {
    name: 'AMG Recruiting',
    imageSrc: amg,
    imageAlt: 'AMG Recruiting Logo',
  },
  {
    name: 'Conmoto',
    imageSrc: conmoto,
    imageAlt: 'conmoto logo',
  },
  {
    name: 'AMD-NETZ',
    imageSrc: amd,
    imageAlt: 'AMD-NETZ Logo',
  },
  {
    name: 'LineUp',
    imageSrc: lineup,
    imageAlt: 'LineUp Logo',
  },
  {
    name: 'cds marketing online',
    imageSrc: cds,
    imageAlt: 'cds marketing online Logo',
  },
  {
    name: 'zmyle',
    imageSrc: zmyle,
    imageAlt: 'zmyle Logo',
  },
  {
    name: 'mwb',
    imageSrc: mwb,
    imageAlt: 'MálagaWorkbay Logo',
  },
  {
    name: 'Diario Sur',
    imageSrc: sur,
    imageAlt: 'Diario Sur Logo',
  },
  {
    name: 'SG Coesfeld 06',
    imageSrc: sg06ah,
    imageAlt: 'SG Coesfeld 06 Logo',
  },
  {
    name: 'promalaga',
    imageSrc: promalaga,
    imageAlt: 'Pro Málaga Logo',
  },
  {
    name: 'dmm',
    imageSrc: dmm,
    imageAlt: 'Digital Marketing Málaga Logo',
  }
]

function Customers() {
    return (
      <div id="customers" className="bg-gradient-to-r from-gray-400 to-gray-200 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          
          <div className="mx-auto mt-10 grid max-w-lg items-center gap-x-8 gap-y-10 grid-cols-2 md:max-w-none md:grid-cols-2 md:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          {customers.map((customer) => (
            <img
              key={customer.name}
              className="col-span-1 max-h-48 w-full object-contain grayscale hover:grayscale-0"
              src={customer.imageSrc}
              alt={customer.imageAlt}
              width={280}
            />
            ))} 
          </div>
        </div>
      </div>
    )
  }
 
  export default Customers;