import learning from './assets/cta-free.png';
import shopifypartner from './assets/ShopifyPlus_Primary_Inverted.svg';

function CTA() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <img
                className="absolute -z-10 top-auto lg:top-0 -bottom-24 left-1/3 lg:left-auto lg:-right-48  w-[50rem] lg:w-[40rem] max-w-none"
                src={learning}
                alt="App screenshot"
                width={400}
                
              /><svg
              viewBox="0 0 1024 1024"
              className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
              aria-hidden="true"
            >
              <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#E935C1" />
                </radialGradient>
              </defs>
            </svg>
            
            <div className="max-w-xl mx-0 lg:flex-auto lg:py-32 lg:text-left">
              <h1 className="text-2xl sm:text-3xl lg:text-2xl tracking-tight text-white">
                Digitales Wachstum gestalten:<br/>smplx macht's einfach!
              </h1>
              <p className="mt-6 mb-6 text-lg leading-8 text-gray-300">
              <span aria-hidden="true">&rarr;</span> Wir unterstützen unsere Kunden digitale Chancen zu entdecken und zu nutzen <br/> 
              </p>
              <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <a className="col-span-2 max-h-12 w-full object-contain lg:col-span-1" href="https://www.google.com/partners/agency?id=8736976301" target="_blank" rel="noreferrer">
                  <img 
                    src="https://www.gstatic.com/partners/badge/images/2023/PartnerBadgeClickable.svg"
                    alt="Google Badge"
                    width={158}
                    height={48}
                  />
                </a>
                  
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src={shopifypartner}
                  alt="Shopify Partner Badge"
                  width={158}
                  height={48}
                />
                
              </div>
              
            </div>
            <div className="relative mt-0 h-80 lg:mt-0">
              
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  export default CTA;