import { Helmet } from 'react-helmet';

function Impressum() {
    return (
      <div>
        <Helmet>
          <title>smplx. - Impressum</title>
        </Helmet>
        
        <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none">
            <div className="lg:pr-8 lg:pt-4">
                <div className="">
                
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-secondary-700 sm:text-4xl">Impressum</h1>
                <p className="mt-6 text-lg leading-8 text-gray-600"><strong>Angaben gemäß § 5 TMG</strong><br />Claudio Gerlich<br />smplx.<br />Osterwicker Str. 20<br />48653 Coesfeld</p>



<p className="mt-6 text-lg leading-8 text-gray-600"><strong>Kontakt</strong><br />Telefon: <a href="tel:+49 (0) 2541 9321402" className="text-secondary-700">+49 (0) 2541 9321402</a><br />E-Mail: <a href="mailto:hallo@smplx.media" className="text-secondary-700">hallo@smplx.media</a></p>



<p className="mt-6 text-lg leading-8 text-gray-600"><strong>Redaktionell verantwortlich</strong><br />Gerlich, Claudio (Betreiber der Website)</p>



<p className="mt-6 text-lg leading-8 text-gray-600"><strong>EU-Streitschlichtung</strong><br />Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:<br /><a href="https://ec.europa.eu/consumers/odr/." className="text-secondary-700">https://ec.europa.eu/consumers/odr/.</a><br />Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>



<p className="mt-6 text-lg leading-8 text-gray-600"><br /><strong>Verbraucherstreitbeilegung/Universalschlichtungsstelle</strong><br />Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer<br />Verbraucherschlichtungsstelle teilzunehmen.</p>



<p className="mt-6 text-lg leading-8 text-gray-600"><strong>Haftungsausschluss</strong><br />Haftung für Inhalte<br />Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewährübernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte odergespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>



<p className="mt-6 text-lg leading-8 text-gray-600">Haftung für Links<br />Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>



<p className="mt-6 text-lg leading-8 text-gray-600">Urheberrecht<br />Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seitenunterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. ​</p>

                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                    
                    <div  className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900"></dt>
                        
                        <dd className="inline"></dd>
                    </div>
                   
                </dl>
                </div>
            </div>
            
            </div>
        </div>
        </div>
        </div>
      );
  }
  
  export default Impressum;