import React from 'react';
import Services from '../components/Services';
import CTA from '../components/CTA';
import Feature from '../components/Feature';
import Intro from '../components/Intro';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';
import Customers from '../components/Customers';
import SwiperSlider from '../components/SwiperSlider';

function Home() {
  return (
    <div>
      <CTA />
      <Intro />
      <Services />
      <SwiperSlider/>
      <Feature />
      <Testimonials />
      <Customers />
      <Contact />
    </div>
    );
}

export default Home;